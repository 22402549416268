.navigation {
    
    &__checkbox {
        display: none;
    }

    &__nav {
        height: 100vh;
        width: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;

        background-image: radial-gradient($bg-main 40%);
        transition: all 0.8s cubic-bezier(0.83, 0, 0.17, 1);
        z-index: 9999;

    }

    &__list {
        position: absolute;
        background-color: $bg-secondary;
        border-radius: 2rem;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        padding: 5rem 8rem;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    &__item {
        &:not(:first-child) {
            margin-top: 1.8rem;
        }
    }

    &__link {

        padding: 1.2rem 1.5rem;
        text-decoration: none;
        color: black;
        &:link,
        &:visited {
            display: inline-block;
            font-size: 1.5rem;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            background-image: linear-gradient(120deg, transparent 0%,transparent 50%, $bg-main 50%);
            background-size: 240%;

            transition: all 0.4s;
        }

        &:hover,
        &:active {
            background-position: 100%;
            
        }
    }

    &__btn {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        position: fixed;
        background-color: $color-grey-light-2;
        top: 7rem;
        left: 7rem;
        z-index: 12000;
        color: black;
        text-align: center;
        cursor: pointer;
        z-index: 13000;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__bg {
        height: 6rem;
        width: 0;
        opacity: 0;

        border-radius: 50%;
        position: fixed;

        top: 6.5rem;
        left: 6.5rem; 

        background-image: radial-gradient(rgba($color-grey-light-1, 0.95), rgba($color-grey-light-2, 0.95));
        box-shadow: rgba($color-grey-light-1, 0.95);
        transition: all 0.8s cubic-bezier(0.83, 0, 0.17, 1);
    }

    &__checkbox:checked ~ &__bg {
        transform: scale(80);
        width: 100vw;
        opacity: 1;
    }

    &__checkbox:checked ~ &__nav {
        width: 100%;
        opacity: 1;
    }


    &__icon {

        position: relative;

        &,
        &::before,
        &::after {
            width: 3rem;
            height: 2px;
            background-color: $color-grey-dark-3;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left:0;

            transition: all .2s;
        }

        &::before {
            top: -.8rem;
        }

        &::after {
            top: .8rem;
        }
    }

    &__btn:hover &__icon::before {
        top: -1rem;
    } 

    &__btn:hover &__icon::after {
        top: 1rem;
    }

    &__checkbox:checked + &__btn &__icon {
        background-color: transparent;
    }
    
    &__checkbox:checked + &__btn &__icon::before {
        top:0;
        transform: rotate(135deg);
    }

    &__checkbox:checked + &__btn &__icon::after {
        top:0;
        transform: rotate(-135deg);
    }
}