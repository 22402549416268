.footer {
    width:100 vw;
    height: 45vh;

    background-color: rgba(234, 234, 234, 0.95);

    display: grid;

    grid-template-columns: repeat(3, 25%);
    grid-template-rows: repeat(3, 5vw);
    justify-content: space-between;
    align-items: center;
    
    font-size: $font-size--tiny;

    padding: 2rem 5rem;

    background-image: $color-grey-light-1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    height: 100%;

    &__list {
        list-style: none;
    }

    &__link {
        text-decoration: none;
        color: black;

        &:hover,
        &:active  {
            opacity: 0.85;
        }

    }
    
    &__segment {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: space-between;

        &--left {
            grid-column: 1 / span 1;
            grid-row: 1 / span 3;
            justify-self: center;
            
        }

        &--center {
            grid-column: 2 / span 1;
            grid-row: 1 / span 3;
            


            ul {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                border-bottom: 1px solid black;
                li {
                    cursor: pointer;
                    
                }
            }
        }

        &--right {
            grid-column: 3 / span 1;
            grid-row: 1 / span 3;
            justify-self: center;
            
            display: flex;
            flex-direction: column;

        }
    }
}