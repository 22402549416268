.page-login {
    
    margin: 0 auto;
    padding: 0 2rem;
    
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 15vw);

    background-color: rgba(239,239,240, .1);

    // justify-content: space-between;

    &__header {
        padding-top: 2rem;
        grid-column: 1 / span 1;
    }

    &__input-container {

        padding: 2rem 2.5rem;

        grid-column: 3 / span 4;
        grid-row: 2 / span 2;

        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(4, 10vw);


        border: solid black 1.5px;
        border-radius: 20px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 95);

    }

    &--email {
        grid-column: 2 / span 2;
        grid-row: 1 / span 1;
        justify-self: center;
    }

    &--password {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
        justify-self: center;
    }
}