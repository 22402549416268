$default-font-size: 1.7rem;
$font-size--ultra-large: 3.7rem;
$font-size--large: 3.1rem;
$font-size--medium: 2.1rem;
$font-size--small: 1.5rem;
$font-size--tiny: 1.1rem;

$color-primary: #1520A6;

$bg-main: #a9abac;
$bg-secondary: #9e9e9e;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;



$color-grey-dark-1: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;


$color-white: #fff;
$color-black: #000;
