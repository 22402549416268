.link {

    color: black;
    text-decoration: none;

    &--common {
        
    

        &:link {
            color:black
        }

        &:active {
            color: black;
        }

        &:hover {
            color: rgba(100, 10, 10, 0.95);
        }

        &:visited {
            color: black;
        }
    }

}