.heading {

    &--main {
        font-size: $font-size--ultra-large;
        font-weight: 800;
        font-family: "Open Sans", sans-serif;
    }

    &--primary {
        font-size: $font-size--large;
        line-height: 1.4rem;
        font-family: "Open Sans", sans-serif;
    }


    &--secondary { 
        font-size: $font-size--medium;
        font-family: "Open Sans", sans-serif;
        font-weight: 800;
        letter-spacing: 0.1rem;
        line-height: 2.2rem;
    }

    &--tertiary {
        font-size: $font-size--small;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        letter-spacing: 0.1rem;

        &-feature {
            grid-row: 2 / span 1;
            align-self: center;
        }
    }

    &--quaternary {
        font-size: $font-size--tiny;
        font-weight: 800;
        font-family: "Open Sans", sans-serif;
    }

    &--primary-trans {
        font-weight: 800;
        display: inline-block;
        font-family: "Open Sans", sans-serif;
        background-image: linear-gradient(to bottom, rgba(2, 2, 2, 0.85), rgba(15, 15, 15, 0.85));
        -webkit-background-clip: text;
        color: transparent;
    }

    &-card {
        text-align: left;
        position: absolute;
        right: 5rem;
        bottom: 2rem;
        width: 25%;
        font-family: "Open Sans", sans-serif;
    }

    &-span {
        padding: 2% 5%;
        box-decoration-break: clone;
        line-height: 3.3rem;
        
        font-size: $font-size--small;
        font-family: "Open Sans", sans-serif;
        background-color: rgba($color-grey-dark-2, 0.95);
        color: black;
    }
}

.paragraph {

    &--main {
        font-size: $font-size--ultra-large;
    }

    &--primary {
        font-size: $font-size--large;
    }

    &--secondary {
        font-size: $font-size--medium;
    } 

    &--tertiary {
        letter-spacing: 0.05rem;
        font-size: $font-size--small;

        font-weight: 300;
    }

    &--quaternary {
        font-size: $font-size--tiny;
    }
}