.card {

    position: relative;
    margin-top: 1rem;

    &__top-3 {

        &--1 {
            grid-column: 1 / span 2;
            grid-row: 1 / span 4;
        }

        &--2 {
            grid-column: 3 / span 2;
            grid-row: 1 / span 4;
        }

        &--3 {
            grid-column: 5 / span 2;
            grid-row: 1 / span 4;
        }
    }

    &-side {
        border-radius: 1rem;
        // padding: 2rem 3.2rem;
        transition: all 1.2s;
        backface-visibility: hidden;
        box-shadow: 0 0.2rem 1.1rem rgba(40, 40, 40, 0.95);

        display: grid;

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 1fr);
        

        &--front {
            background-color: $color-grey-light-1;
            height: 100%;
            width: 100%;
            position: absolute;
            
            &__heading {
                margin-top: 1rem;
                grid-column: 1 / span 3;
                grid-row: 1 / span 2;
                margin-left: 1.2rem;
            }
            transform: rotateY(0);


            &__info {
                grid-column: 1 / span 3;
                grid-row: 3 / span 2;
                align-self: center;
                justify-self: center;

                &__list {
                    margin-top: 2rem;
                    li {
                        border-bottom: 1px solid black;
                        text-align: center;
                    }
                } 
           
            }
        }  

        &--back {
            background-color: $color-grey-light-1;
            height: 100%;
            width: 100%;
            position : absolute;
            transform: rotateY(-180deg);

            display: grid;
            grid-template-columns: repeat(3, 30%);
            grid-template-rows: repeat(8, 1fr);
            justify-content: space-evenly;
            // transform: rotateY(0);

            &__heading {
                grid-row: 4 / span 1;
                grid-column: 1 / span 3;
                align-self: end;
                justify-self: center;
            }
        }
    }

    &__img {

        grid-column: 1 / span 3;
        grid-row: 1 / span 2;

        justify-self: stretch;
        align-self: stretch;
        background-image: url('../../public/images/microscope--1.webp');
        background-size: cover;
        z-index: 20;
        position: relative;
        border-radius: 0.5rem;
        
    }

    &__list {
        grid-column: 1 / span 3;
        grid-row: 5 / span 2;

        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        

        &-item {
            margin-top: .5rem;
            cursor: pointer;
            border-bottom: 1px solid black;
            text-align: center;
            letter-spacing: 0.15rem;
            font-size: $font-size--tiny;
        }
    }

    &__fields-container {
        grid-column: 1 / span 3;
        grid-row: 8 / span 1;

        display: flex;
        flex-direction: row;
        align-items: center;

        justify-content: space-around;
        margin-bottom: 5%;
    }

    &__input-field--order {
        padding: 1.2rem 2.5rem;

        background-color: rgba(0, 68, 128, 0.79);
        border-radius: 10rem;

        transition: all, 0.5s;

        &:hover {
            background-color: rgba(0, 68, 128);
            color: white;
        }
    }

    &:hover &-side--front {
        transform: rotateY(-180deg);
    }

    &:hover &-side--back {
        transform: rotateY(0);
    }
    
    &__container {
        &--contrast {
            
            margin-top: 10%;
            padding: 0 2rem;
            grid-row: 1 / span 2;
            grid-column: 1 / span 3;

            display: flex;
            flex-direction: column;
            font-size: $font-size--tiny;
            align-items: center;
            justify-content: space-around;

            h5 {
                text-align: center;
            }
        }
    }
}