.atomi {

    &__icon {
        display: block;
        
        grid-column: 1 / span 1;
        grid-row: 1 / span 3;
        
        height: 20%;
        width: 20%;

        color: black;
    }
}