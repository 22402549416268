@keyframes slideFromRight {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromLeft {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideFromBottom {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: "Roboto", sans-serif;
}

html {
  font-size: 62.5%;
}
html ul {
  list-style: none;
}
@media (max-width: 37.5em) {
  html {
    font-size: 40%;
  }
}
@media (min-width: 37.51em) and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 56.26em) and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
}

:root {
  --scroll: 0;
}

.heading--main {
  font-size: 3.7rem;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}
.heading--primary {
  font-size: 3.1rem;
  line-height: 1.4rem;
  font-family: "Open Sans", sans-serif;
}
.heading--secondary {
  font-size: 2.1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  letter-spacing: 0.1rem;
  line-height: 2.2rem;
}
.heading--tertiary {
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.heading--tertiary-feature {
  grid-row: 2/span 1;
  align-self: center;
}
.heading--quaternary {
  font-size: 1.1rem;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}
.heading--primary-trans {
  font-weight: 800;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  background-image: linear-gradient(to bottom, rgba(2, 2, 2, 0.85), rgba(15, 15, 15, 0.85));
  -webkit-background-clip: text;
  color: transparent;
}
.heading-card {
  text-align: left;
  position: absolute;
  right: 5rem;
  bottom: 2rem;
  width: 25%;
  font-family: "Open Sans", sans-serif;
}
.heading-span {
  padding: 2% 5%;
  box-decoration-break: clone;
  line-height: 3.3rem;
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
  background-color: rgba(153, 153, 153, 0.95);
  color: black;
}

.paragraph--main {
  font-size: 3.7rem;
}
.paragraph--primary {
  font-size: 3.1rem;
}
.paragraph--secondary {
  font-size: 2.1rem;
}
.paragraph--tertiary {
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.paragraph--quaternary {
  font-size: 1.1rem;
}

.u-margin-top--large {
  margin-top: 3.7rem;
}

.u-margin-top--medium {
  margin-top: 2.2rem;
}

.u-margin-top--small {
  margin-top: 0.55rem;
}

.u-margin-bottom--large {
  margin-bottom: 3.7rem;
}

.u-margin-bottom--medium {
  margin-bottom: 2.2rem;
}

.u-margin-bottom--small {
  margin-bottom: 0.55rem;
}

.u-center {
  text-align: center;
}

.u-margin-left--small {
  margin-left: 5%;
}

.atomi__icon {
  display: block;
  grid-column: 1/span 1;
  grid-row: 1/span 3;
  height: 20%;
  width: 20%;
  color: black;
}

.banner {
  z-index: 30;
}
.banner__container {
  height: 75vh;
  width: 100%;
  position: relative;
}
.banner__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.banner__img--1 {
  width: 100%;
  height: 100%;
}
.banner-arrow {
  position: absolute;
  cursor: pointer;
}
.banner-arrow--left {
  top: 50%;
  left: 2%;
  color: black;
  z-index: 30;
}
.banner-arrow--right {
  top: 50%;
  right: 2%;
  color: black;
  z-index: 30;
}

.brand {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
}

.card {
  position: relative;
  margin-top: 1rem;
}
.card__top-3--1 {
  grid-column: 1/span 2;
  grid-row: 1/span 4;
}
.card__top-3--2 {
  grid-column: 3/span 2;
  grid-row: 1/span 4;
}
.card__top-3--3 {
  grid-column: 5/span 2;
  grid-row: 1/span 4;
}
.card-side {
  border-radius: 1rem;
  transition: all 1.2s;
  backface-visibility: hidden;
  box-shadow: 0 0.2rem 1.1rem rgba(40, 40, 40, 0.95);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
}
.card-side--front {
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: rotateY(0);
}
.card-side--front__heading {
  margin-top: 1rem;
  grid-column: 1/span 3;
  grid-row: 1/span 2;
  margin-left: 1.2rem;
}
.card-side--front__info {
  grid-column: 1/span 3;
  grid-row: 3/span 2;
  align-self: center;
  justify-self: center;
}
.card-side--front__info__list {
  margin-top: 2rem;
}
.card-side--front__info__list li {
  border-bottom: 1px solid black;
  text-align: center;
}
.card-side--back {
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: rotateY(-180deg);
  display: grid;
  grid-template-columns: repeat(3, 30%);
  grid-template-rows: repeat(8, 1fr);
  justify-content: space-evenly;
}
.card-side--back__heading {
  grid-row: 4/span 1;
  grid-column: 1/span 3;
  align-self: end;
  justify-self: center;
}
.card__img {
  grid-column: 1/span 3;
  grid-row: 1/span 2;
  justify-self: stretch;
  align-self: stretch;
  background-image: url("../../public/images/microscope--1.webp");
  background-size: cover;
  z-index: 20;
  position: relative;
  border-radius: 0.5rem;
}
.card__list {
  grid-column: 1/span 3;
  grid-row: 5/span 2;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.card__list-item {
  margin-top: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid black;
  text-align: center;
  letter-spacing: 0.15rem;
  font-size: 1.1rem;
}
.card__fields-container {
  grid-column: 1/span 3;
  grid-row: 8/span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5%;
}
.card__input-field--order {
  padding: 1.2rem 2.5rem;
  background-color: rgba(0, 68, 128, 0.79);
  border-radius: 10rem;
  transition: all, 0.5s;
}
.card__input-field--order:hover {
  background-color: rgb(0, 68, 128);
  color: white;
}
.card:hover .card-side--front {
  transform: rotateY(-180deg);
}
.card:hover .card-side--back {
  transform: rotateY(0);
}
.card__container--contrast {
  margin-top: 10%;
  padding: 0 2rem;
  grid-row: 1/span 2;
  grid-column: 1/span 3;
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
  align-items: center;
  justify-content: space-around;
}
.card__container--contrast h5 {
  text-align: center;
}

.catalogue {
  margin: 0 auto;
  padding-top: 2rem;
  display: block;
  margin-top: 2rem;
  margin-left: 3.5rem;
}
.catalogue__header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 5rem;
  gap: 2.5rem;
}
.catalogue__categories-container {
  grid-column: 1/span 1;
  border-radius: 2rem;
  box-shadow: 0 0.1rem 0.1rem rgba(123, 123, 123, 0.95);
  background-color: #eee;
  background-size: cover;
}
.catalogue__container {
  margin: 0;
  padding: 3rem 10rem;
  padding-left: 2rem;
}
.catalogue__category {
  margin: 0 auto;
  padding: 2rem 5rem;
  grid-column: 2/span 3;
  grid-row: 1/span 8;
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0 0.1rem 0.1rem rgba(123, 123, 123, 0.95);
  background-color: #eee;
  background-size: cover;
}
.catalogue__item {
  margin: 0 auto;
  font-size: 1.5rem;
  line-height: 2.5rem;
  transition: all 0.5s;
  border-bottom: solid 2px rgba(0, 0, 0, 0);
}
.catalogue__item__composition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 0.5rem;
}
.catalogue__item__link {
  display: inline-block;
  margin-left: 1.2rem;
  text-decoration: none;
  color: black;
  transition: all 0.5s;
}
.catalogue__item__link:hover {
  transform: scale(1.08);
}
.catalogue__item__icon {
  display: inline-block;
  cursor: pointer;
}
.catalogue__item__subcategory {
  margin-left: 3rem;
  cursor: pointer;
  font-size: 1.1rem;
}
.catalogue__item__subcategory:first-child {
  margin-top: 1.2rem;
}
.catalogue__item__subcategory:not(:first-child) {
  margin-top: 0.4rem;
}
.catalogue__item:not(:first-child) {
  margin-top: 1.2rem;
}

.category {
  position: relative;
  background-color: red;
}
.category__item {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1f);
  justify-content: space-around;
  align-content: space-around;
  margin: 0.5rem 0;
  padding: 2rem;
  padding-left: 1.2rem 0.5rem;
  border: 1px solid rgba(232, 232, 232, 0.85);
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 0.8rem rgba(123, 123, 123, 0.95);
  transition: all 0.4s;
  cursor: pointer;
}
.category__item:first-child {
  margin-top: 2rem;
}
.category__item:hover {
  transform: scale(1.08);
}
.category__item__shape {
  grid-column: 6/span 2;
  grid-row: 1/span 3;
  align-self: center;
  width: 15rem;
  height: 15rem;
  background-color: rgb(135, 189, 211);
  float: right;
  shape-outside: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  position: relative;
}
.category__item__content {
  grid-column: 1/span 4;
  grid-row: 1/span 3;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.category__item__img {
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: all 0.8s;
  transform: scale(1.2);
}
.category__item__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 1.1rem;
  color: white;
  opacity: 0;
  text-align: center;
  transition: all 0.8s;
  backface-visibility: hidden;
}
.category__item:hover .category__item__caption {
  opacity: 1;
  transform: translate(-50%, -50%);
}
.category__item:hover .category__item__img {
  transform: scale(1);
  filter: blur(3px) brightness(80%);
}
.category__description {
  margin-top: 1rem;
}
.category__footer {
  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
}
.category__footer__description {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.category__footer__description p {
  margin-left: 0.5rem;
}
.category__footer__applications {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.category__footer__applications ul {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
}

.container__items {
  position: absolute;
  width: 30vw;
  height: 30vh;
  padding: 0.2rem 0.2rem;
  margin: 0.2rem;
  border-radius: 0.2rem;
  background-color: #f7f7f7;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  z-index: 999;
}
.container__items-hidden {
  display: none;
}
.container__items__content {
  display: flex;
  flex-direction: column;
}
.container__items__item {
  cursor: pointer;
  padding: 1.2rem 1.2rem;
  font-size: 1.7rem;
  transition: all 0.5s;
}
.container__items__item:not(:first-child) {
  margin-top: 1.5rem;
}
.container__items__item:hover {
  transform: scale(1.015);
  background-color: #d8d8d8;
}
.container__items__item-no-bg:hover {
  transform: scale(1.015);
  background-color: white;
}

.footer {
  width: 100 vw;
  height: 45vh;
  background-color: rgba(234, 234, 234, 0.95);
  display: grid;
  grid-template-columns: repeat(3, 25%);
  grid-template-rows: repeat(3, 5vw);
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  padding: 2rem 5rem;
  background-image: #f7f7f7;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 100%;
}
.footer__list {
  list-style: none;
}
.footer__link {
  text-decoration: none;
  color: black;
}
.footer__link:hover, .footer__link:active {
  opacity: 0.85;
}
.footer__segment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.footer__segment--left {
  grid-column: 1/span 1;
  grid-row: 1/span 3;
  justify-self: center;
}
.footer__segment--center {
  grid-column: 2/span 1;
  grid-row: 1/span 3;
}
.footer__segment--center ul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid black;
}
.footer__segment--center ul li {
  cursor: pointer;
}
.footer__segment--right {
  grid-column: 3/span 1;
  grid-row: 1/span 3;
  justify-self: center;
  display: flex;
  flex-direction: column;
}

.icon__feature {
  margin-top: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  font-size: 3.5rem;
  justify-self: center;
  grid-row: 1/span 2;
}

.atomi {
  cursor: pointer;
}
.atomi__icon {
  display: block;
  grid-row: 1/span 3;
  grid-column: 1/span 1;
  font-size: 3.5rem;
}

.input__login {
  display: block;
  outline: none;
  border: solid black 2px;
  border-radius: 15px;
  padding-left: 0.7rem;
}

.label__login {
  display: block;
  padding-left: 0.7rem;
}

.item__name {
  letter-spacing: 0.095rem;
  font-weight: 700;
}
.item__value {
  letter-spacing: 0.085rem;
}
.item--li {
  letter-spacing: 0.1rem;
  text-align: center;
}
.item__container {
  display: grid;
  grid-template-columns: repeat(10vw, 8);
  grid-template-rows: repeat(10vh, 8);
}
.item__header {
  grid-column: 1/span 8;
  grid-row: 1/span 3;
  margin: 5% 2%;
  padding: 10% 5%;
  background-color: #f7f7f7;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-content: space-around;
  align-items: space-around;
}
.item__header--left {
  padding: 0 5rem;
  padding-top: 10rem;
  padding-bottom: 5rem;
  grid-column: 1/span 4;
  grid-row: 1/span 4;
  border: 0.5px solid grey;
  border-radius: 5rem;
  box-shadow: 0 0.01rem 0.5rem rgba(0, 0, 0, 0.95);
  font-size: 1.7rem;
  line-height: 2.2rem;
  background-color: white;
}
.item__header--left h1 {
  line-height: 3.7rem;
}
.item__header--right {
  grid-column: 5/span 4;
  grid-row: 1/span 4;
  justify-self: center;
}
.item__main {
  grid-column: 3/span 4;
  grid-row: 4/span 3;
  padding: 0 5%;
  padding-bottom: 20%;
  font-size: 1.1rem;
}
.item__main__specs {
  margin-top: 1.2rem;
}
.item__main__list {
  margin-top: 1.2rem;
}
.item__main__description p {
  margin-top: 1.2rem;
  letter-spacing: 0.095rem;
}
.item__heading--primary {
  animation-name: slideFromLeft;
  animation-duration: 1.5s;
}
.item__description__preview {
  margin-top: 2rem;
  animation-name: slideFromRight;
  animation-duration: 1.5s;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(123, 123, 123, 0));
  letter-spacing: 0.12rem;
}

.link {
  color: black;
  text-decoration: none;
}
.link--common:link {
  color: black;
}
.link--common:active {
  color: black;
}
.link--common:hover {
  color: rgba(100, 10, 10, 0.95);
}
.link--common:visited {
  color: black;
}

.modal {
  position: fixed;
  animation-name: slideFromBottom;
  animation-duration: 1.5s;
  z-index: 999;
}
.modal__outside {
  inset: 0;
  background-color: rgba(247, 247, 247, 0.55);
}
.modal__inside {
  inset: 2rem 27%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(10, 1fr);
  gap: 2rem 2rem;
  justify-content: space-around;
  background-color: #eee;
  border-radius: 1rem;
}
.modal__field {
  grid-column: 2/span 4;
}
.modal__field--heading {
  grid-column: 2/span 4;
  grid-row: 2/span 1;
  justify-self: center;
}
.modal__field--name {
  grid-row: 3/span 1;
}
.modal__field--email {
  grid-row: 4/span 1;
}
.modal__field--contact {
  grid-row: 5/span 1;
}
.modal__field--comment {
  grid-row: 6/span 2;
}
.modal__field--btn {
  grid-row: 9/span 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.modal__field-item {
  width: 100%;
}

.navbar {
  display: grid;
  padding: 1.2rem 2.2rem;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: space-between;
  position: block;
  margin: 0 auto;
  color: #000;
  background-color: rgba(232, 232, 233, 0.107);
  box-shadow: 0 2px 4px #1520A6;
  position: sticky;
  z-index: 999;
}
.navbar__icon-container {
  grid-column: 1/span 1;
  grid-row: 1/span 3;
  cursor: pointer;
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar__icon-link {
  position: relative;
}
.navbar__icon-link svg {
  width: 100%;
}
.navbar__link--email {
  font-size: 1.7rem;
  color: black;
  text-decoration: none;
}
.navbar__link--order {
  padding: 0.4rem 1.4rem;
  background-color: rgb(17, 67, 193);
  font-size: 1.2rem;
  border-radius: 5rem;
}
.navbar--left {
  grid-column: 4/span 4;
  grid-row: 1/span 3;
  justify-self: start;
  align-self: center;
  font-size: 1.7rem;
  list-style: none;
}
.navbar--right {
  grid-column: 8/span 1;
  grid-row: 1/span 3;
  justify-self: end;
  align-self: center;
  font-size: 1.5rem;
}
.navbar__link-left {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-left: 1.5rem;
  padding-left: 2.5rem;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
}
.navbar__link-left:not(:first-child) {
  margin-left: 1rem;
}
.navbar__link-left:hover {
  opacity: 0.85;
}
.navbar__link-right {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-right: 1.5rem;
  font-weight: 300;
}
.navbar__link-right:not(:last-child) {
  margin-right: 1rem;
}
.navbar__link-right:hover {
  opacity: 0.85;
}
.navbar__input-container {
  padding: 0.95rem 0.75rem;
  grid-column: 2/span 2;
  grid-row: 1/span 3;
  justify-self: stretch;
  align-self: stretch;
  position: relative;
}
.navbar__input {
  display: inline-block;
  width: 60%;
  min-height: 130%;
  color: black;
  background-color: #eee;
  padding-left: 0.75rem;
  border: 0;
  border-radius: 7.5px;
  transition: all 2s;
}
.navbar__input:focus {
  outline: none;
  width: 100%;
}
.navbar__input:hover {
  width: 100%;
}

.navigation__checkbox {
  display: none;
}
.navigation__nav {
  height: 100vh;
  width: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-image: radial-gradient(#a9abac 40%);
  transition: all 0.8s cubic-bezier(0.83, 0, 0.17, 1);
  z-index: 9999;
}
.navigation__list {
  position: absolute;
  background-color: #9e9e9e;
  border-radius: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5rem 8rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.navigation__item:not(:first-child) {
  margin-top: 1.8rem;
}
.navigation__link {
  padding: 1.2rem 1.5rem;
  text-decoration: none;
  color: black;
}
.navigation__link:link, .navigation__link:visited {
  display: inline-block;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #a9abac 50%);
  background-size: 240%;
  transition: all 0.4s;
}
.navigation__link:hover, .navigation__link:active {
  background-position: 100%;
}
.navigation__btn {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  background-color: #eee;
  top: 7rem;
  left: 7rem;
  z-index: 12000;
  color: black;
  text-align: center;
  cursor: pointer;
  z-index: 13000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigation__bg {
  height: 6rem;
  width: 0;
  opacity: 0;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  left: 6.5rem;
  background-image: radial-gradient(rgba(247, 247, 247, 0.95), rgba(238, 238, 238, 0.95));
  box-shadow: rgba(247, 247, 247, 0.95);
  transition: all 0.8s cubic-bezier(0.83, 0, 0.17, 1);
}
.navigation__checkbox:checked ~ .navigation__bg {
  transform: scale(80);
  width: 100vw;
  opacity: 1;
}
.navigation__checkbox:checked ~ .navigation__nav {
  width: 100%;
  opacity: 1;
}
.navigation__icon {
  position: relative;
}
.navigation__icon, .navigation__icon::before, .navigation__icon::after {
  width: 3rem;
  height: 2px;
  background-color: #333;
  display: inline-block;
}
.navigation__icon::before, .navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.8rem;
}
.navigation__icon::after {
  top: 0.8rem;
}
.navigation__btn:hover .navigation__icon::before {
  top: -1rem;
}
.navigation__btn:hover .navigation__icon::after {
  top: 1rem;
}
.navigation__checkbox:checked + .navigation__btn .navigation__icon {
  background-color: transparent;
}
.navigation__checkbox:checked + .navigation__btn .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}
.navigation__checkbox:checked + .navigation__btn .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.section__header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.95);
}
.section__brands {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 5rem;
  letter-spacing: 0.2rem;
  background-color: rgba(227, 227, 227, 0.35);
  color: white;
  font-size: 1.7rem;
}
.section__brands__field {
  cursor: pointer;
  transition: all, 1s;
  position: relative;
}
.section__brands__field:hover {
  transform: scale(1.2);
}
.section__brands__brand {
  width: 5rem;
  height: 5rem;
}
.section__top-3 {
  display: grid;
  grid-template-columns: repeat(6, 8vw);
  grid-template-rows: repeat(4, 20vh);
  gap: 0.2rem;
  justify-content: space-around;
  padding: 2rem 1rem;
}
.section__collection--header {
  background-color: antiquewhite;
  height: 80vh;
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.section__collection--header h1 {
  position: absolute;
  grid-column: 1/span 2;
  grid-row: 4/span 2;
  padding: 2rem 3.5rem;
  color: white;
}
.section__collection--header p {
  position: absolute;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
  padding: 2rem 3.5rem;
  border-radius: 1rem;
  background-color: rgba(250, 250, 250, 0.95);
  box-shadow: 0 0.05rem 0.09rem rgba(255, 255, 25, 0.85);
  grid-row: 3/span 2;
  grid-column: 1/span 3;
}
.section__catalogue {
  display: grid;
  grid-template-columns: repeat(6, 10%);
  grid-template-rows: repeat(20, 1fr);
  gap: 0 2rem;
  justify-content: space-around;
  align-content: start;
}
.section__catalogue__header {
  grid-column: 1/span 6;
  grid-row: 1/span 1;
}
.section__catalogue__main {
  grid-column: 1/span 6;
  grid-row: 2/span 19;
}
.section__company-features {
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-content: space-between;
  align-items: space-between;
  gap: 0 2rem;
  background-color: #f7f7f7;
}
.section__company-features__card {
  margin-top: 2.2rem;
  padding: 2rem 2.5rem;
  grid-row: 2/span 3;
  background-image: #a9abac;
  border-radius: 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5rem 0rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0.1rem 0.25rem 0.3rem rgba(0, 0, 0, 0.95);
  justify-content: center;
  align-items: space-between;
}
.section__company-features__card:hover {
  transform: scale(1.05);
  box-shadow: 0.2rem 0.35rem 0.7rem rgba(0, 0, 0, 0.95);
}
.section__company-features__card--1 {
  grid-column: 1/span 2;
  justify-self: stretch;
}
.section__company-features__card--2 {
  grid-column: 3/span 2;
  justify-self: stretch;
}
.section__company-features__card--3 {
  grid-column: 5/span 2;
  justify-self: stretch;
}
.section__company-features__card--4 {
  grid-column: 7/span 2;
  justify-self: stretch;
}
.section__company-features__card--5 {
  grid-column: 9/span 2;
  justify-self: stretch;
}
.section__company-features__card__heading {
  align-self: center;
  justify-self: center;
  grid-column: 1/span 4;
  grid-row: 2/span 1;
}
.section__company-features__card__description {
  letter-spacing: 0.05rem;
  align-self: center;
  text-align: center;
  grid-column: 1/span 4;
  grid-row: 3/span 1;
}
.section__streams {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 75vh);
  align-items: space-between;
  justify-content: space-around;
}
.section__streams__container {
  padding: 0 10%;
}
.section__streams__container__img {
  border-radius: 2rem;
  padding: auto auto;
  width: 100%;
}
.section__streams__container--1 {
  grid-column: 1/span 8;
  grid-row: 1/span 1;
}
.section__streams__container--2 {
  grid-column: 1/span 8;
  grid-row: 2/span 1;
}
.section__streams__container--3 {
  grid-column: 1/span 8;
  grid-row: 3/span 1;
}
.section__streams__container--4 {
  grid-column: 1/span 8;
  grid-row: 4/span 1;
}
.section__streams__container--left {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 0 5rem;
}
.section__streams__container--left-content {
  grid-column: 1/span 4;
  margin-top: 20%;
}
.section__streams__container--left-content-composition {
  grid-column: 5/span 4;
  grid-row: 1/span 4;
  padding: 10%;
}
.section__streams__container--right {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 0 5rem;
}
.section__streams__container--right-content {
  grid-column: 5/span 4;
  grid-row: 1/span 4;
  margin-top: 20%;
}
.section__streams__container--right-content-composition {
  grid-column: 1/span 4;
  grid-row: 1/span 4;
  padding: 10%;
}
.section__clients {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 28vh 1vh 2vh;
  gap: 2.5rem 3.5rem;
  justify-content: space-evenly;
  background-color: rgba(117, 117, 117, 0.508);
}
.section__clients__img {
  grid-column: 1/span 3;
  grid-row: 1/span 3;
  background-color: rgba(224, 223, 223, 0.806);
  border-radius: 1rem;
  box-shadow: 0.35rem 0.2rem 0.4rem rgba(0, 0, 0, 0.95);
}
.section__clients__type {
  display: block;
  grid-row: 4/span 1;
  align-self: self-end;
  font-weight: 800;
  letter-spacing: 0.1rem;
}
.section__clients__description {
  grid-column: 1/span 3;
  grid-row: 5/span 1;
  align-self: center;
  letter-spacing: 0.15rem;
}
.section__clients-card {
  padding: 0.5rem 0.7rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height: 100%;
  width: 85%;
}
.section__clients-card--1 {
  grid-column: 1/span 2;
  grid-row: 1/span 3;
  justify-self: center;
}
.section__clients-card--2 {
  grid-column: 3/span 2;
  grid-row: 1/span 3;
  justify-self: center;
}
.section__clients-card--3 {
  grid-column: 5/span 2;
  grid-row: 1/span 3;
  justify-self: center;
}
.section__clients-card--4 {
  grid-column: 7/span 2;
  grid-row: 1/span 3;
  justify-self: center;
}

.page {
  position: relative;
}
.about-us {
  background-color: #1520A6;
}
.about-us__header {
  display: grid;
  margin-top: 5rem;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 1fr);
  justify-content: space-between;
  align-items: center;
}
.about-us__header h3 {
  grid-column: 5/span 4;
  grid-row: 1/span 1;
  align-self: end;
}
.about-us__header__preview {
  grid-column: 1/span 4;
  grid-row: 2/span 4;
  justify-self: center;
  align-self: self-start;
}
.about-us__header__preview img {
  transform: translateX(-15%);
  width: 100%;
  height: 5rem;
}
.about-us__header__logo-container {
  grid-column: 5/span 3;
  grid-row: 2/span 4;
  justify-content: center;
  align-items: center;
  align-self: self-start;
  padding: 0 0;
  background-color: rgba(248, 248, 248, 0.165);
  display: inline-grid;
  grid-template-columns: repeat(3, 30%);
  grid-template-rows: repeat(3, 20%);
  gap: 2.5rem 0.03rem;
}
.about-us__header__logo-container__icon {
  cursor: pointer;
  width: 50%;
  transition: all 0.5s;
}
.about-us__header__logo-container__icon:hover {
  transform: scale(1.2);
}
.about-us__header__logo-container__icon--1 {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.about-us__header__logo-container__icon--2 {
  grid-column: 2/span 1;
  grid-row: 1/span 1;
}
.about-us__header__logo-container__icon--3 {
  grid-column: 3/span 1;
  grid-row: 1/span 1;
}
.about-us__header__logo-container__icon--4 {
  grid-column: 1/span 1;
  grid-row: 2/span 1;
}
.about-us__main {
  margin-top: 10rem;
  display: grid;
  grid-template-columns: repeat(3, 20vw);
  grid-template-rows: repeat(6, 1fr);
  justify-content: space-around;
  align-content: space-around;
  background-color: rgba(247, 247, 247, 0.65);
}
.about-us__main--1 {
  grid-column: 1/span 3;
  grid-row: 1/span 3;
  display: grid;
  grid-template-columns: repeat(8, 0.3fr);
  grid-template-rows: repeat(4, 0.3fr);
  background-size: cover;
  justify-items: space-between;
  align-items: center;
  padding: 2% 5%;
}
.about-us__main--1__description {
  grid-column: 1/span 4;
  grid-row: 1/span 3;
  padding: 5rem;
  font-size: 1.1rem;
}
.about-us__main--1__composition {
  grid-column: 5/span 4;
  grid-row: 1/span 3;
  padding: 5rem;
  padding: 0;
}
.about-us__main--1__composition__img {
  border-radius: 2rem;
  box-shadow: 0 0.02rem 0.1rem rgb(0, 0, 0);
  width: 100%;
}
.about-us__footer {
  padding: 2rem 10rem;
  background-color: #f7f7f7;
}
.about-us__footer__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 0.2fr);
  align-items: space-between;
  justify-items: center;
}
.about-us__footer__content__info {
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.about-us__footer__content__list {
  grid-column: 2/span 1;
  grid-row: 1/span 1;
  font-size: 1.7rem;
}

.page-login {
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 15vw);
  background-color: rgba(239, 239, 240, 0.1);
}
.page-login__header {
  padding-top: 2rem;
  grid-column: 1/span 1;
}
.page-login__input-container {
  padding: 2rem 2.5rem;
  grid-column: 3/span 4;
  grid-row: 2/span 2;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 10vw);
  border: solid black 1.5px;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgb(0, 0, 0);
}
.page-login--email {
  grid-column: 2/span 2;
  grid-row: 1/span 1;
  justify-self: center;
}
.page-login--password {
  grid-column: 1/span 2;
  grid-row: 2/span 1;
  justify-self: center;
}

@media (width <= 1500px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .navbar__link-left-display {
    display: none;
  }
}
@media (width < 1080px) {
  .section__streams {
    display: flex;
    flex-direction: column;
  }
  .section__streams__container {
    display: flex;
    flex-direction: column;
  }
  .section__streams__container--left-content {
    margin-top: 1rem;
  }
  .section__streams__container--right-content {
    margin-top: 1rem;
  }
  .section__company-features {
    display: none;
  }
  .section__top-3 {
    display: none;
  }
  .section__top-3__heading {
    display: none;
  }
  .catalogue__header {
    display: flex;
    flex-direction: column;
  }
  .catalogue__container {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .catalogue__item {
    margin-left: 1.2rem;
  }
  .footer {
    display: flex;
    flex-direction: row;
  }
  .footer__segment--center {
    display: none;
  }
  .container__items__item__composition {
    display: flex;
    flex-direction: column;
  }
  .container__items__item__img-container {
    margin-bottom: 3rem;
  }
  .item__header {
    display: flex;
    flex-direction: column;
  }
  .item__header--right {
    position: relative;
    text-align: center;
  }
  .item__header--right img {
    width: 50%;
  }
  .item__header--left {
    margin-top: 5rem;
  }
  .modal__inside {
    inset: 7rem 25rem;
  }
}
@media (width > 500px) {
  .navigation {
    display: none;
  }
}
@media (width < 700px) {
  .banner {
    height: 40vh;
  }
  .navbar--left {
    display: none;
  }
  .navbar__link-left-display {
    display: none;
  }
  .footer {
    display: flex;
    flex-direction: row;
  }
  .footer__segment--center {
    display: none;
  }
  .modal__inside {
    inset: 20% 10%;
  }
}

