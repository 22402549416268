@mixin inputFont {
    letter-spacing: 0.095rem;
    line-height: 1.1rem;
}

@mixin clearfix {
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}

@mixin link {
    &:link,
    &:active {
        text-decoration: none;
    }

    &:visited {
    
    }
}

// 0 - 600 px = phone
// 600 - 900 px = tablet
// 900 - 1200 px = tablet landscape
// 1200 - 1800 px = desktop
// +1800 = large screen

// @mixin breakpoints {
//     @media (max-width: 600px) {
//         @content;
//     }

//     @media (min-width: 601px) and (max-width: 900px) {
//         @content;
//     }

//     @media (min-width: 901px) and (max-width: 1200px) {
//         @content;
//     }

//     @media (min-width: 1201px) and (max-width: 1800px) {
//         @content;
//     }

//     @media (min-width: 1801px) {
//         @content;
//     }
// }

// 1em = 16px

@mixin sizify($breakpoint) {
    @if($breakpoint == phone) {
        @media (max-width: 37.5em)  {@content}; // 600 px
    }

    @if($breakpoint == tablet) {
        @media (min-width: 37.51em) and (max-width: 56.25em) {@content};
    }

    @if($breakpoint == tablet-land) {
        @media (min-width: 56.26em) and (max-width: 75em) {@content};
    }

    @if($breakpoint == large-screen) {
        @media (min-width: 112.5em) {@content};
    }
}