.navbar {

    display: grid;
    padding: 1.2rem 2.2rem;

    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-content: space-between;
    
    position: block;

    margin: 0 auto;

    color: $color-black;

    background-color: rgba(232, 232, 233, 0.107);

    box-shadow: 0 2px 4px $color-primary;
    position: sticky;
    z-index: 999;

    &__icon {

        &-container {

            grid-column: 1 / span 1;
            grid-row: 1 / span 3;
            cursor: pointer;
            position: relative;
    
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-link {
            position: relative;
            svg {
                width: 100%;
            }
        }
    }

    &__link {
        &--email {
            font-size: $default-font-size;
            color: black;
            text-decoration: none;
        }

        &--order {
            padding: 0.4rem 1.4rem;
            background-color: rgb(17, 67, 193);
            font-size: 1.2rem;
            
            border-radius: 5rem;
        }
    }   

    &--left {
        grid-column: 4 / span 4;
        grid-row: 1 / span 3;
        
        justify-self: start;
        align-self: center;

        font-size: $default-font-size;
        list-style: none;
    }

    &--right {
        grid-column: 8 / span 1;
        grid-row: 1 / span 3;

        justify-self: end;
        align-self: center;

        font-size: $font-size--small;
    }


    &__link-left {
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin-left: 1.5rem;
        padding-left: 2.5rem;

        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 300;

        &:not(:first-child) {
            margin-left: 1rem;
        }

        &:hover {
            opacity: 0.85;
        }
    }

    &__link-right {
        display: inline-block;
        cursor: pointer;
        position: relative;
        margin-right: 1.5rem;
        font-weight: 300;
        
        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover {
            opacity: 0.85;
        }
    }

    &__input-container {
        padding: .95rem .75rem;
        grid-column: 2 / span 2;
        grid-row: 1 / span 3;
        justify-self: stretch;
        align-self: stretch;
        position: relative;
    }

    &__input {
        
        display: inline-block;

        width: 60%;
        min-height: 130%;
        color: black;
        background-color: $color-grey-light-2;
        padding-left: .75rem;

        border: 0;
        border-radius: 7.5px;
        transition: all 2s;

        &:focus {
            outline: none;
            width: 100%;
        }

        &:hover {
            width: 100%;
        }
    }
};