.modal {
    position: fixed;

    animation-name: slideFromBottom;
    animation-duration: 1.5s;

    z-index: 999;
    

    &__outside {
        inset: 0;
        background-color: rgba($color-grey-light-1, 0.55);
    }

    &__inside {
        inset: 2rem 27%;
     

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(10, 1fr);
        gap: 2rem 2rem;

        justify-content: space-around;
    

        background-color: $color-grey-light-2;

        border-radius: 1rem;

    }   

    &__field {
        grid-column: 2 / span 4;
        
        &--heading {
            grid-column: 2 / span 4;
            grid-row: 2 / span 1;

            justify-self: center;
        }

        &--name {
            grid-row: 3 / span 1;
        }

        &--email {
            grid-row: 4 / span 1;
        }

        &--contact {
            grid-row: 5 / span 1;
        }

        &--comment {
            grid-row: 6 / span 2;
        }

        &--btn {
            grid-row: 9 / span 1;

            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }



        &-item {
            width: 100%;
        }
    }

    
}