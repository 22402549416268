@keyframes slideFromRight {
    0% {
        transform: translateX(20%);
         opacity: 0
    }
    100% {
        transform: translateX(0); 
        opacity: 1
    }
}

@keyframes slideFromLeft {
    0% {
        transform: translateX(-20%);
         opacity: 0
    }
    100% {
        transform: translateX(0); 
        opacity: 1
    }
}

@keyframes slideFromBottom {
    0% {
        transform: translateY(-20%);
         opacity: 0
    }
    100% {
        transform: translateX(0); 
        opacity: 1
    }
}