// .main-page {
//     width: 100vw;
// }

.section {

    &__header {
        
        width: 100%;
        background-color: rgba(0, 0, 0, 0.95);
    }

    &__main {

    }

    &__brands {
        
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        padding: 2rem 5rem;

        letter-spacing: .2rem;

        background-color: rgba(227, 227, 227, 0.35);
        color: white;
        font-size: 1.7rem;


        &__field {
            cursor: pointer;
            transition: all, 1s;

            position: relative;

            &:hover {
                transform: scale(1.2);
            }
            
        }

        &__brand {
            width: 5rem;
            height: 5rem;
        }

    }

    &__top-3 {
        display: grid;
        grid-template-columns: repeat(6, 8vw);
        grid-template-rows: repeat(4, 20vh);
        gap: 0.2rem;
        justify-content: space-around;
        padding: 2rem 1rem;
    }

    &__collection {

        &--header {
            background-color: antiquewhite;
            height: 80vh;
            position: relative;
            // background-image: url(../../public/images/banner-hero--1.jpg);
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(4, 1fr);

            h1 {
                position: absolute;
                grid-column: 1 / span 2;
                grid-row: 4 / span 2;
                padding: 2rem 3.5rem;
                color: white;
                
            }

            p {
                position: absolute;
                margin-left: 2rem;
                margin-bottom: 1.5rem;

                padding: 2rem 3.5rem;
                border-radius: 1rem;

                background-color:  rgba(250, 250, 250, 0.95);

                box-shadow: 0 0.05rem .09rem rgba(255, 255, 25, 0.85);

                grid-row: 3 / span 2;
                grid-column: 1 / span 3;
            }
        }
    }


    &__catalogue {
    
        display: grid;
        grid-template-columns: repeat(6, 10%);
        grid-template-rows: repeat(20, 1fr);

        gap: 0 2rem;
        justify-content: space-around;
        align-content: start;
        
        &__header {
            grid-column: 1 / span 6;
            grid-row: 1 / span 1;
        }

        &__main {
            grid-column: 1 / span 6;
            grid-row: 2 / span 19;
        }

        &__optical-microscopes {
        
        }

        &__electron-microscopes {

        }

        &__sample-preparation {

        }

        &__mechanical-testing {

        }

        &__consumables {

        }
    }

    &__company-features {
        padding: 0 2rem;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-between;
        align-items: space-between;
        gap: 0 2rem;
        background-color: $color-grey-light-1;
        

        &__card {

            margin-top: 2.2rem;
            padding: 2rem 2.5rem;
            grid-row: 2 / span 3;

            background-image: $bg-main;

            border-radius: 1rem;

            cursor: pointer;


            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 5rem 0rem;
            position: relative;
            transition: all 0.3s ease-in-out;
            box-shadow: 0.1rem 0.25rem .3rem rgba(0, 0, 0, 0.95);
            justify-content: center;
            align-items: space-between;
            &:hover {
                transform: scale(1.05);
                box-shadow: 0.2rem 0.35rem .7rem rgba(0, 0, 0, 0.95);
            }

            &--1 {
                grid-column: 1 / span 2;
                justify-self: stretch;
            }
            
            &--2 {
                grid-column: 3 / span 2;
                justify-self: stretch;
            }
    
            &--3 {
                grid-column: 5 / span 2;
                justify-self: stretch;
            }
    
            &--4 {
                grid-column: 7 / span 2;
                justify-self: stretch;
            }
    
            &--5 {
                grid-column: 9 / span 2;
                justify-self: stretch;
            }

            &__heading {
                align-self: center;
                justify-self: center;
                grid-column: 1 / span 4;
                grid-row: 2 / span 1;
            }

            &__description {
                letter-spacing: 0.05rem;
                align-self: center;
                text-align: center;
                grid-column: 1 / span 4;
                grid-row: 3 / span 1;
            }
        }

    }

    &__streams {
        display: grid;

        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(4, 75vh);
        align-items: space-between;
        justify-content: space-around;
        
        &__container {
         
            padding: 0 10%;

            &__img {
                border-radius: 2rem;
                padding: auto auto;
                width: 100%;
            }

            &--1 {
                grid-column: 1 / span 8;
                grid-row: 1 / span 1;
            }

            &--2 {
                grid-column: 1 / span 8;
                grid-row: 2 / span 1;
            }

            &--3 {
                grid-column: 1 / span 8;
                grid-row: 3 / span 1;
            }

            &--4 {
                grid-column: 1 / span 8;
                grid-row: 4 / span 1;
            }
            
            &--left {

                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(4, 1fr);
                gap: 0 5rem;
                
                
                &-content {
                    grid-column: 1 / span 4;
                    
                    margin-top: 20%;

                    &-composition {
                        grid-column: 5 / span 4;
                        grid-row: 1 / span 4;

                        padding: 10%;
                    }
                }
                
            }

            &--right {
                display: grid;
                grid-template-columns: repeat(8, 1fr);
                grid-template-rows: repeat(4, 1fr);
                gap: 0 5rem;


                &-content {
                    grid-column: 5 / span 4;
                    grid-row: 1 / span 4;
                    margin-top: 20%;

                    &-composition {
                        grid-column: 1 / span 4;
                        grid-row: 1 / span 4;
                        padding: 10%;
                    }
                }
            }
            

        }
    }

    &__clients {
        margin-top: 4rem;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 28vh 1vh 2vh;
        gap: 2.5rem 3.5rem;
        
        justify-content: space-evenly;

        background-color: rgba(117, 117, 117, 0.508);

        &__img {
            grid-column: 1 / span 3;
            grid-row: 1 / span 3;
            background-color: rgba(224, 223, 223, 0.806);
            
                            
            border-radius: 1rem;
            box-shadow: 0.35rem .2rem .4rem rgba(0, 0, 0, 0.95);
        }

        &__type {
            display: block;
            grid-row: 4 / span 1;
            align-self: self-end;
            font-weight: 800;
            letter-spacing: 0.1rem;
        }

        &__description {
            grid-column: 1 / span 3;
            grid-row: 5 / span 1;
            align-self: center;
            letter-spacing: 0.15rem;
        }
    
        &-card {

            padding: 0.5rem .7rem;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(5, 1fr);
            height: 100%;
            width: 85%;


            &--1 {
                grid-column: 1 / span 2;
                grid-row: 1 / span 3;    
                justify-self: center;
            }
    
            &--2 {
                grid-column: 3 / span 2;
                grid-row: 1 / span 3;
                justify-self: center;
            }
    
            &--3 {
                grid-column: 5 / span 2;
                grid-row: 1 / span 3;
                justify-self: center;
            }
    
            &--4 {
                grid-column: 7 / span 2;
                grid-row: 1 / span 3;
                justify-self: center;
            }
        }

        
    }
};