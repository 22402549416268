.icon {

    &__feature {
        margin-top: 1rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        display: block;
        font-size: 3.5rem;  
        justify-self: center;
        grid-row: 1 / span 2;
    }
}

.atomi {

    cursor: pointer;

    &__icon {
        display: block;
        grid-row: 1 / span 3;
        grid-column: 1 / span 1;
        font-size: 3.5rem;
    }
}
