.input {
    &__login {
        display: block;
        outline: none;
        border: solid black 2px;
        border-radius: 15px;
        padding-left: .7rem;
    }
}

.label {
    &__login {
        display: block;
        padding-left: .7rem;
    }
}