.category {

    position: relative;
    background-color: red;

    &__item {

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1f);
        justify-content: space-around;
        align-content: space-around;

        &:first-child {
            margin-top: 2rem;
        }

        margin: 0.5rem 0;
        padding: 2rem;
        padding-left: 1.2rem .5rem;
        border: 1px solid rgba(232, 232, 232, 0.85);
        border-radius: 1rem;

        box-shadow: .2rem .2rem .8rem rgba(123, 123, 123, 0.95);
        

        transition: all .4s;
        
        cursor: pointer;

        &:hover {
            transform: scale(1.08);
        }

        &__shape {
            grid-column: 6 / span 2;
            grid-row: 1 / span 3;
            align-self: center;
            width: 15rem;
            height: 15rem;
            background-color: rgb(135, 189, 211);
            
            float: right;
            shape-outside: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
            
            position: relative;
        }

        &__content {
            grid-column: 1 / span 4;
            grid-row: 1 / span 3;

            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }

        &__img {
            width: 100%;
            height: 100%;
            background-size: cover;
            
            transition: all .8s;
            transform: scale(1.2);
        }

        &__caption {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            font-size: $font-size--tiny;
            color: white;
            opacity: 0;
            text-align: center;

            transition: all .8s;
            backface-visibility: hidden;
            
        }

        &:hover &__caption {
            opacity: 1;
            transform: translate(-50%, -50%);
        }
        &:hover &__img {
            transform: scale(1);
            filter: blur(3px) brightness(80%);
        }
    }


    
    &__description {
        margin-top: 1rem;
        
    }

    &__footer {
        display: flex;
        flex-direction: column;
        font-size: $font-size--tiny;

        &__description {
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                margin-left: 0.5rem;
            }
        }

        &__applications {

            margin-top: 1.5rem;

            display: flex;

            flex-direction: row;
            align-items: center;

            ul {
                margin-left: 0.5rem;
                display: flex;

                flex-direction: row
            }
        }
    }
}