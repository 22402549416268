*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: "Roboto", sans-serif;
}

html {
    ul {
        list-style: none;
    }

    font-size: 62.5%;

    @include sizify(phone) {
        font-size: 40%;
    }

    @include sizify(tablet) {
        font-size: 50%;
    }

    @include sizify(tablet-land) {
        font-size: 56.25%;
    }

    @include sizify(large-screen) {
        font-size: 75%;
    }
}

body {
    box-sizing: border-box;
}

:root {
    --scroll: 0;
}