@media (width <= 1500px) {
    .navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;


        &__link {
        
            &-left-display {
                display: none;
            }
        }
    }

}

@media (width < 1080px) {
    
    .section { 
        
        &__streams {
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
            &--left-content {
                margin-top: 1rem;
            }

            &--right-content {
                margin-top: 1rem;
            }
            
        }

    }
        &__company-features {
            display: none;       
        }

        &__top-3 {
            display: none;

            &__heading {
                display: none;
            }
        }
    }

    .catalogue {
        // &__container {
        //     display: none;
        // }

        &__header {
            display: flex;
            flex-direction: column;
        }

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: start;
        }

        &__item {
            margin-left: 1.2rem;
        }

    }

    
    .footer {

        display: flex;
        flex-direction: row;

        &__segment {
            &--center {
                display: none;
            }
        }
    }

    // CONTAINERS

    .container{
        &__items__item {

            &__composition {
                display: flex;
                flex-direction: column;
            }


            &__img-container {
                margin-bottom: 3rem;
            }
        }
    }


    // COMPONENTS
    .item {
        &__header {
            display: flex;
            flex-direction: column;

            &--right {
                position: relative;
                text-align: center;
                img {
                    width: 50%;
                    
                }
            }

            &--left {
                margin-top: 5rem;
            }
        }
    }


    .modal {
        &__outside {
            
        }

        &__inside {
            inset: 7rem 25rem;
        }
    }
}

@media (width > 500px) {
    .navigation {
        display: none;
    }

}

@media (width < 700px) {

    .banner {
        height: 40vh;
    }

    .navbar--left {
        display: none;
    }
    .navbar__link-left-display {
        display: none;
    }

    .footer {


        display: flex;
        flex-direction: row;

        &__segment {
            &--center {
                display: none;
            }
        }
    }

    .modal {
        &__outside {
            
        }

        &__inside {
            inset: 20% 10%;
        }

        &__field {
            
        }
    }
}

