.item {


    &__name {
        letter-spacing: 0.095rem;
        font-weight: 700;
    }

    &__value {
        letter-spacing: 0.085rem;
    }

    &--li {
        letter-spacing: 0.1rem;
        text-align: center;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(10vw, 8);
        grid-template-rows: repeat(10vh, 8);
        
  
    }

    &__header {
        grid-column: 1 / span 8;
        grid-row: 1 / span 3;
        margin: 5% 2%;
        padding: 10% 5%;

        background-color: $color-grey-light-1;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-around;
        align-items: space-around;


        &--left {
            padding: 0 5rem;
            padding-top: 10rem;
            padding-bottom: 5rem;
            grid-column: 1 / span 4;
            grid-row: 1 / span 4;
            border: 0.5px solid grey;
            border-radius: 5rem;
            box-shadow: 0 .01rem 0.5rem rgba($color-black, 0.95);
            font-size: 1.7rem;
            line-height: 2.2rem;
            background-color: white;

            h1 {
                line-height: $font-size--ultra-large;
            }
        }

        &--right {
            grid-column: 5 / span 4;
            grid-row: 1 / span 4;
            justify-self: center;
        }
    }

    &__main {
        grid-column: 3 / span 4;
        grid-row: 4 / span 3;

        padding: 0 5%;
        padding-bottom: 20%;

        font-size: $font-size--tiny;

        &__specs {
            margin-top: 1.2rem;
        }

        &__list {
            margin-top: 1.2rem;
        }

        &__description {
            p {
                margin-top: 1.2rem;
                letter-spacing: 0.095rem;
            }
            
        }
    }

    &__heading {
        &--primary {

         
            animation-name: slideFromLeft;
            animation-duration: 1.5s;
        }
    }

    &__description {


        &__preview {
            margin-top: 2rem;
            animation-name: slideFromRight;
            animation-duration: 1.5s;

            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(123, 123, 123, 0));
            letter-spacing: .12rem;
        }


    }
}