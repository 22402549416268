.catalogue {

    margin: 0 auto;
    padding-top: 2rem;


    display: block;
   
    margin-top: 2rem;
    margin-left: 3.5rem;

    &__header {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        // grid-template-rows: repeat(8, 10vh);
        margin: 0 5rem;
        gap: 2.5rem;

    }

    &__categories-container {
        grid-column: 1 / span 1;
        // grid-row: 1 / span 8;
        border-radius: 2rem;
        box-shadow: 0 .1rem 0.1rem rgba(123, 123, 123, .95);

        background-color: $color-grey-light-2;
        background-size: cover;
    }

    &__container {
        margin: 0;
        padding: 3rem 10rem;
        padding-left: 2rem;
    }

    &__category { 
        margin: 0 auto;
        padding: 2rem 5rem;
        grid-column: 2 / span 3;    
        grid-row: 1 / span 8;
        width: 100%;
        
        border-radius: 2rem;
        box-shadow: 0 .1rem 0.1rem rgba(123, 123, 123, .95);

        background-color: $color-grey-light-2;
        background-size: cover;
    }

    &__item {
        margin: 0 auto;
        font-size: $font-size--small;
        line-height: 2.5rem;
        
        transition: all .5s;
        border-bottom: solid 2px rgba(0,0,0, 0);
        
        &__composition {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            margin-bottom: 0.5rem;
        }

        &__link {
            display: inline-block;
            margin-left: 1.2rem;

            text-decoration: none;
            color: black;
            transition: all .5s;

            &:hover {
                transform: scale(1.08);
            };
        }

        &__icon {
            display: inline-block;
            cursor: pointer;
        }

        &__subcategory {
            &:first-child {
                margin-top: 1.2rem;
            }
            margin-left: 3rem;
            cursor: pointer;
            font-size: $font-size--tiny;
            &:not(:first-child) {
                margin-top: 0.4rem;
            }
        }
        

        &:not(:first-child) {
            margin-top: 1.2rem;
        }

    };


};