.u-margin-top--large {
    margin-top: 3.7rem;
}

.u-margin-top--medium {
    margin-top: 2.2rem;
}

.u-margin-top--small {
    margin-top: .55rem;
}

.u-margin-bottom--large{
    margin-bottom: 3.7rem;
}

.u-margin-bottom--medium {
    margin-bottom: 2.2rem;
}

.u-margin-bottom--small {
    margin-bottom: .55rem;
}

.u-center {
    text-align: center;
}


.u-margin-left--small {
    margin-left: 5%;
}