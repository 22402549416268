.container {
    
    &__items {
        // position: relative;

        position: absolute;
        width: 30vw;
        height: 30vh;
        padding: .2rem .2rem;
        margin: 0.2rem;
        border-radius: .2rem;
        
        
        background-color: $color-grey-light-1;
        overflow-y: scroll;
        overflow-x:hidden;
        scrollbar-width: thin;
        z-index: 999;

        &-hidden {
            display: none;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__item {
            cursor: pointer;
            padding: 1.2rem 1.2rem;
            font-size: $default-font-size;

            transition: all 0.5s;

            &:not(:first-child) {
                margin-top: 1.5rem;
            }
            
            &:hover {
                transform: scale(1.015);
                background-color: darken($color-grey-light-1, 12);
            }


            &-no-bg {

                &:hover {
                    transform: scale(1.015);
                    background-color: white;
                }
            }
        }
    }
}