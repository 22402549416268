.about-us {

    background-color: $color-primary;
    

    &__header {
        display: grid;

        margin-top: 5rem;

        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(5, 1fr);

        justify-content: space-between;
        align-items: center;
        
        h3 {
            grid-column: 5 / span 4;
            grid-row: 1 / span 1;
            align-self: end;
        }

        &__preview {
            grid-column: 1 / span 4;
            grid-row: 2 / span 4;

            justify-self: center;
            align-self: self-start;

            img {
                transform: translateX(-15%);
                width: 100%;
                height: 5rem;
            }
        }

        &__logo-container {
            grid-column: 5 / span 3;
            grid-row: 2 / span 4;
            
            // margin-top: 5rem;

            justify-content: center;
            align-items: center;
            align-self: self-start;
            padding: 0 0;
            
            background-color: rgba(248, 248, 248, 0.165);

            display: inline-grid;
            grid-template-columns: repeat(3, 30%);
            grid-template-rows: repeat(3, 20%);
            gap: 2.5rem .03rem;
            
            &__icon {
                cursor: pointer;
                width: 50%;
                transition: all 0.5s;

                &:hover {
                    transform: scale(1.2);
                }

                
                &--1 {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;

                }

                &--2 {
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;
                }

                &--3 {
                    grid-column: 3 / span 1;
                    grid-row: 1 / span 1;
                }

                &--4 {
                    grid-column: 1 / span 1;
                    grid-row: 2 / span 1;
                }
            }
        }

    }

    &__main {
        margin-top: 10rem;
        display: grid;
        grid-template-columns: repeat(3, 20vw);
        grid-template-rows: repeat(6, 1fr);
        justify-content: space-around;
        align-content: space-around;

        background-color: rgba($color-grey-light-1, 0.65);
        
        &--1 {
            grid-column: 1 / span 3;
            grid-row: 1 / span 3;

            display: grid;
            
            grid-template-columns: repeat(8, .3fr);
            grid-template-rows: repeat(4, .3fr);


            background-size: cover;
            // border-radius: 2rem;

            justify-items: space-between;
            align-items: center;
            
            
            padding: 2% 5%;
            // gap: .5rem .2rem;

            &__description {
                grid-column: 1 / span 4;
                grid-row: 1 / span 3;
                padding: 5rem;

                font-size: $font-size--tiny;
            }

            &__composition {
                grid-column: 5 / span 4;
                grid-row: 1 / span 3;
                padding: 5rem;
                padding: 0;
                &__img {
                    border-radius: 2rem;
                    box-shadow: 0 0.02rem .1rem rgba(0,0,0,95); 
                    width: 100%;
                }
            }
        }
    }

    &__footer {
        
        padding: 2rem 10rem;
        background-color: $color-grey-light-1;

        &__content {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 0.2fr);

            align-items: space-between;
            justify-items: center;

            &__info {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }

            &__list {
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;

                font-size: $default-font-size;
            }
        }
    }
}