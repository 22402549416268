.banner {
    z-index: 30;
    
    &__container {
        height: 75vh;
        width: 100%;

        position: relative;

    }

    &__img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &--1 {

            width: 100%;
            height: 100%;
        }
    }

    &-arrow {
        
        position: absolute;
        cursor: pointer;

        &--left {
            top: 50%;
            left: 2%;
            color: black;
            z-index: 30;
        }

        &--right {
            top: 50%;
            right: 2%;
            color: black;
            z-index: 30;
        }
    }
}